import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {ServicePracticesService} from "@services/data-source-tables/service-practices.service";
import {ServicePracticesSettings} from "@/table-settings/tables/service-practices-settings";

@Component({
  selector: 'app-service-practices',
  templateUrl: './service-practices.component.html',
  styleUrls: ['./service-practices.component.scss']
})
export class ServicePracticesComponent {

  source: LocalDataSource;
  settings = ServicePracticesSettings.gridSettings();

  constructor(private servicePracticesService: ServicePracticesService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadServicePractices();
    });

    this.loadServicePractices();

    Registry.getInstance().set('ServicePracticesComponent', this)
  }

  loadServicePractices() {
    this.servicePracticesService.getAllServicePractices().subscribe(servicePractices => {
      const options = [];
      for (const servicePractice of servicePractices) {
        options.push({
          value: servicePractice.id,
          title: servicePractice.descrizione
        });
      }

      this.settings.columns.tipoServizioAssicurativo.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    this.servicePracticesService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
