import {Component, OnInit} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {RentalPracticesService} from "@services/data-source-tables/rental-practices.service";
import {RentalPracticesSettings} from "@/table-settings/tables/rental-practices-settings";

@Component({
  selector: 'app-rental-practices',
  templateUrl: './rental-practices.component.html',
  styleUrls: ['./rental-practices.component.scss']
})
export class RentalPracticesComponent implements OnInit {

  source: LocalDataSource;
  settings = RentalPracticesSettings.gridSettings();

  constructor(private rentalPracticesService: RentalPracticesService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadRentalPractices();
    });

    Registry.getInstance().set('RentalPracticesComponent', this)

    this.loadRentalPractices();
  }

  ngOnInit(): void {
  }

  loadRentalPractices() {
    this.rentalPracticesService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
