import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class QuadratureAccountingPracticesSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "documentNo",
        text: "Document No."
      },
      {
        name: "numeroPraticaRef",
        text: "Nr. Pratica"
      },
      {
        name: "numeroRegistrazione",
        text: "Numero Registrazione"
      },
      {
        name: "dataScritturaContabilita",
        text: "Data Scrittura",
        type: "date"
      },
      {
        name: "reasonCode",
        text: "Reason Code"
      },
      {
        name: "sourceCode",
        text: "Source Code"
      },
      {
        name: "descrizione",
        text: "Descrizione",
      },
      {
        name: "saldo",
        text: "Saldo",
        type: "currency"
      },
    ];
  }
}
