export class RentalPracticeModel {
  id: number;
  praticaId: any;
  targa: string;
  marca: string;
  famiglia: string;
  modello: string;
  statoContratto: string;
  durataContratto: number;
  kmContratto: number;
  dataMad: string;
  dataScadenza: string;
  canoneFinanziario: number;
  canoneServizio: number;
  canoneTotale: number;
  percEsuberoKm: number;
  codiceCentroCosto: string;
  descCentroCosto: string;
  tipoGomme: string;
  costoEccedenzaKm: number;
  rimborsoKm: number;
  rc: string;
  ifk: string;
  pai: string;
  categoriaVeicolo: string;
  noleggi: any[];
}
