import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {VehicleAccessorySettings} from "@/table-settings/tables/vehicle-accessory-settings";
import {VehicleAccessoryService} from "@services/data-source-tables/vehicle-accessory.service";
import {LoggerService} from "@services/logger.service";

@Component({
  selector: 'app-vehicle-accessory',
  templateUrl: './vehicle-accessory.component.html',
  styleUrls: ['./vehicle-accessory.component.scss']
})
export class VehicleAccessoryComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = VehicleAccessorySettings.gridSettings();

  constructor(private vehicleAccessoryService: VehicleAccessoryService) {
    this.loadVehicleAccessory();

    this.vehicleAccessoryService.getAllModels().subscribe(models => {
      const options = [];
      for (const model of models) {
        options.push({
          value: model.id,
          title: model.descrizioneModello
        });
      }

      this.settings.columns.anagSiglaModello.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    Registry.getInstance().set('VehicleAccessoryComponent', this)
  }

  loadVehicleAccessory() {
    this.vehicleAccessoryService.getAll().subscribe((accessoryServices) => {
      LoggerService.log(accessoryServices, 'accessoryServices')

      this.source.load(accessoryServices);
    });
  }
}
