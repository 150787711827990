import {Component, inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {CashMovementsAnalisisSettings} from "@/table-settings/cash-management/cash-movements-analisis-settings";
import {CashMovementsAnalisisDataSource} from "@components/new-table/data-sources/cash-movements-analisis-data-source";
import {CashMovementsAnalisisService} from "@services/cash-movements.analisis.service";
import {saveAs} from "file-saver";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-table-ms',
  templateUrl: './cash-movements-analisis.component.html',
  styleUrls: ['./cash-movements-analisis.component.scss']
})
export class CashMovementsAnalisisComponent implements OnInit {
  modalTrigger$ = new Subject<any>();
  triggerDate$ = new Subject<any>();
  triggerSelection$ = new Subject<any>();
  reloadTrigger$ = new Subject<any>();
  displayedColumns = CashMovementsAnalisisSettings.getColumnsSetting();
  dataSource = new CashMovementsAnalisisDataSource(this.cashMovementsAnalysisService);
  toastr = inject(ToastrService);
  columns = [
    {field: 'dataMovimento', header: 'Data Movimento'},
    {field: 'saldoInizialeContanti', header: 'Saldo Iniziale Contanti'},
    {field: 'saldoInizialeAssegni', header: 'Saldo Iniziale Assegni'},
    {field: 'sommaEntrataContanti', header: 'Entrata Contanti'},
    {field: 'sommaEntrataAssegni', header: 'Entrata Assegni'},
    {field: 'sommaUscitaContanti', header: 'Uscita Contanti'},
    {field: 'sommaVersamentoContanti', header: 'Versamento Contanti'},
    {field: 'sommaVersamentoAssegni', header: 'Versamento Assegni'},
    {field: 'saldoFinaleContanti', header: 'Saldo Finale Contanti'},
    {field: 'saldoFinaleAssegni', header: 'Saldo Finale Assegni'},
    {field: 'saldoTotale', header: 'Saldo Totale'}
  ];
  subColumns = [
    {field: 'dataMovimento', header: 'Data Movimento'},
    {field: 'tipoMovimento', header: 'Tipo Movimento'},
    {field: 'tipoPagamento', header: 'Tipo Pagamento'},
    {field: 'descrizioneMovimento', header: 'Descrizione Movimento'},
    {field: 'codice', header: 'Codice'},
    {field: 'importoAssegni', header: 'Importo Assegni'},
    {field: 'importoContanti', header: 'Importo Contanti'},
    {field: 'abbuono', header: 'Abbuono'},
    {field: 'numeroAssegno', header: 'Numero Assegno'},
    {field: 'banca', header: 'Banca'},
    {field: 'creator', header: 'Creator'},
    {field: 'note', header: 'Note'}
  ];
  data = [];
  formatRows = {
    'dataMovimento': 'date',
    'saldoFinaleAssegni': 'currency',
    'saldoFinaleContanti': 'currency',
    'saldoInizialeAssegni': 'currency',
    'saldoInizialeContanti': 'currency',
    'saldoTotale': 'currency',
    'sommaEntrataAssegni': 'currency',
    'sommaEntrataContanti': 'currency',
    'sommaUscitaContanti': 'currency',
    'sommaVersamentoAssegni': 'currency',
    'sommaVersamentoContanti': 'currency'
  }
  formatSubRows = {
    'dataMovimento': 'date',
    'importoContanti': 'currency',
    'importoAssegni': 'currency',
  }
  protected readonly faFileExcel = faFileExcel;

  constructor(private cashMovementsAnalysisService: CashMovementsAnalisisService) {
  }

  async ngOnInit(): Promise<void> {
    await this.cashMovementsAnalysisService.getAnalysisDashboard().then(balancingAccounts => {
      this.data = this.formatData(balancingAccounts);
    });

    this.modalTrigger$.subscribe({
      next: () => {
        this.reloadTrigger$.next(1)
      }
    });
  }

  downloadExcelExport(row: any) {
    let formattedDate = '';
    const parts = row.dataMovimento.split('/');

    if (parts.length === 3) {
      formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    this.cashMovementsAnalysisService.downloadExportExcel(formattedDate).subscribe((res) => {
      saveAs(res, 'Export-quadratura' + row.dataMovimento + '.xlsx');
    });
  }

  protected formatData(balancingAccounts: [any]) {
    balancingAccounts.forEach(account => {
      for (const key in this.formatRows) {

        if (this.formatRows.hasOwnProperty(key)) {
          const formatType = this.formatRows[key];

          if (account.hasOwnProperty(key)) {
            account[key] = this.formatValue(account[key], formatType);
          }
        }
      }

      if (account.movimenti.lenght !== 0) {
        this.formatDataSubRows(account.movimenti);
      }
    });

    return balancingAccounts;
  }

  protected formatDataSubRows(accountMovements: [any]) {
    accountMovements.forEach(movements => {
      for (const key in this.formatSubRows) {

        if (this.formatSubRows.hasOwnProperty(key)) {
          const formatType = this.formatSubRows[key];

          if (movements.hasOwnProperty(key)) {
            movements[key] = this.formatValue(movements[key], formatType);
          }
        }
      }
    });
  }

  protected formatValue(value: any, formatType: string): any {
    if (formatType === 'date') {
      return new Date(value).toLocaleDateString(); // Assuming value is a date string or timestamp
    } else if (formatType === 'currency') {
      return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(value);
    }
    return value;
  }
}
