import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class SellerPracticeInvoiceSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "importoNetto",
        text: "Importo Netto",
        type: "currency"
      },
      {
        name: "percentualeIva",
        text: "Percentuale IVA",
      }
    ];
  }
}
