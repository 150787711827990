import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {
  RentalPracticeFormMeta
} from "@pages/rental-management/rent-practice-detail/rent-practice-detail-form/rental-practice-form-meta";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {RentalPracticeService} from "@services/data-source-tables/rental-practice.service";

@Component({
  selector: 'app-rent-practice-form',
  templateUrl: '../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentPracticeDetailFormComponent extends DynamicFormComponent implements OnInit {

  constructor(protected router: Router, protected userService: UserService, private rentalPracticeService: RentalPracticeService, protected toastr: ToastrService, public dialog: MatDialog) {
    super(toastr, userService)

    this.setupMeta()
  }

  ngOnInit(): void {
  }

  public onSubmit() {
    let rentalPracticeModel = <RentalPracticeModel>this.form.getRawValue()

    this.rentalPracticeService.updateRentalPractice(rentalPracticeModel).subscribe({
      next: (response: RentalPracticeModel) => {
        let practiceId = response.id

        this.toastr.success('Pratica aggiornata correttamente!');

        this.router.navigate([`rental-practices/detail-practice/${practiceId}`])
      },
      error: (error) => {
        LoggerService.error('savePractice failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della pratica.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = RentalPracticeFormMeta.getMeta()
  }
}
