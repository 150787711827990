import {Component} from '@angular/core';
import {faCircleInfo, faFile} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "@/interfaces/tab";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {LoggerService} from "@services/logger.service";
import {RentalPracticeService} from "@services/data-source-tables/rental-practice.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-rent-practice-detail',
  templateUrl: './rent-practice-detail.component.html',
  styleUrls: ['./rent-practice-detail.component.scss']
})
export class RentPracticeDetailComponent {
  data: RentalPracticeModel
  tabs: Array<Tab> = []
  protected readonly faFile = faFile;
  private id: number;

  constructor(private rentalPracticeService: RentalPracticeService, private router: Router, private route: ActivatedRoute,) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.tabs = [
      {
        'title': 'Anagrafica noleggio',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Cliente noleggio',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Movimenti noleggio',
        'faIcon': faCircleInfo
      },
    ]
    this.data = {} as RentalPracticeModel;

    this.refreshPractice()
  }

  async refreshPractice() {
    LoggerService.log('refreshPractice')

    await this.rentalPracticeService.getRentalPractice(this.id).then(practice => {
      LoggerService.log(practice, 'getRentalPractice')

      this.data = <RentalPracticeModel>practice
    });


    return 1;
  }
}
