import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {RentalAgencySettings} from "@/table-settings/tables/rental-agency-settings";
import {RentalAgencyService} from "@services/data-source-tables/rental-agency.service";

@Component({
  selector: 'app-rental-agency',
  templateUrl: './rental-agency.component.html',
  styleUrls: ['./rental-agency.component.scss']
})
export class RentalAgencyComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = RentalAgencySettings.gridSettings();

  constructor(private rentalAgencyService: RentalAgencyService) {
    Registry.getInstance().set('RentalAgencyComponent', this)

    this.loadRentalAgency();
  }

  public loadRentalAgency() {
    this.rentalAgencyService.getAll().subscribe((rentalAgencies) => {
      this.source.load(rentalAgencies);
    });
  }
}
