import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {Component} from "@angular/core";
import {ButtonComponent} from "@components/button/button.component";
import {ToastrService} from "ngx-toastr";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";
import {VehicleAccessoryService} from "@services/data-source-tables/vehicle-accessory.service";

@Component({
  selector: 'app-button-vehicle-accessory-delete',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDeleteVehicleAccessoryComponent extends ButtonComponent {

  faIcon = faTrashCan;
  private vehicleAccessoryModel: VehicleAccessoryModel;
  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, protected vehicleAccessoryService: VehicleAccessoryService, protected toastr: ToastrService) {
    super(router, toastr);

    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.vehicleAccessoryModel = <VehicleAccessoryModel>this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteSeller => {
      if (deleteSeller) {
        this.vehicleAccessoryService.deleteVehicleAccessory(this.vehicleAccessoryModel.id).subscribe({
          next: () => {
            this.toastr.success('Accessorio Veicolo cancellato correttamente!');

            Registry.getInstance().get('VehicleAccessoryComponent').loadVehicleAccessory()
          },
          error: (error) => {
            LoggerService.error('deleteSeller failed!')
            LoggerService.error(error);

            this.toastr.error(`Errore durante la cancellazione dell'Accessorio Veicolo.`);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo Accesorio Veicolo?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata gli Accesorio Veicolo non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
