import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {CashMovementsForm} from "@/form-settings/cash-movements-form";
import {OptionSelect} from "@/models/option-select.model";
import {Registry} from "@/registry.service";
import {CashMovementsService} from "@services/cash-movements.service";
import {BankPaymentModel, CashMovementsModel} from "@/models/cash-movements.model";
import {LoggerService} from "@services/logger.service";
import {Subject} from "rxjs";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-new-cash-movements-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class NewCashMovementsFormComponent extends DynamicFormComponent implements OnInit {

  @Input() departmentSelected: Subject<any>;
  protected typeMovementSelected: string = 'ENTRATA';
  private departments: OptionSelect[] = [];
  private movementTypes: OptionSelect[] = [];
  private department: string
  private actionAfterSave: "saveDuplicate" | "saveNew" | "save" = "save"
  private movementId: any;
  private isAccountingUser: boolean = false
  private isNewMovement: boolean = false
  private isAdminUser: boolean = false;
  private departmentUpdating: string;
  private typePayments: OptionSelect[] = [];
  private banks: BankPaymentModel[] = [];

  constructor(private route: ActivatedRoute, protected router: Router, protected userService: UserService, protected cashMovementsService: CashMovementsService, protected toastr: ToastrService, public dialog: MatDialog) {
    super(toastr, userService)

    this.cashMovementsService.getDepartments().subscribe((departments) => {
      let departmentOptions: OptionSelect[] = []
      this.department = departments[0]

      for (let department of departments) {
        departmentOptions.push({
          'key': department,
          'value': department
        })
      }

      this.departments = departmentOptions

      this.refreshForm()
    })
    this.cashMovementsService.getMovementTypes().subscribe((movementTypes) => {
      let movementTypeOptions: OptionSelect[] = []

      for (let movementType of movementTypes) {
        movementTypeOptions.push({
          'key': movementType,
          'value': movementType
        })
      }

      this.movementTypes = movementTypeOptions

      this.refreshForm()
    })
    this.cashMovementsService.getBankAccounts().subscribe((banks) => {
      this.banks = banks

      this.refreshForm()
    })

    this.route.params.subscribe((params: Params) => {
      if (!params['id']) {
        this.secondButton = 'Salva e nuovo'
        this.thirdButton = 'Salva e duplica'
        this.isNewMovement = true
      } else {
        this.movementId = params['id'];

        this.loadDataMovement()
      }
    })

    this.isAccountingUser = getCurrentUserRoles().includes(Role.Accounting)
    this.isAdminUser = getCurrentUserRoles().includes(Role.Admin)
    this.typePayments = [
      {key: 'CONTANTI', value: 'Contanti'},
      {key: 'ASSEGNO', value: 'Assegno'},
      {key: 'POS', value: 'POS'},
      {key: 'BONIFICO', value: 'Bonifico'},
    ]

    Registry.getInstance().set('NewCashMovementsFormComponent', this)

    this.setupMeta()
  }

  ngOnInit(): void {
  }

  typePaymentChange(typePayment: string) {
    if (this.form.controls['tipoMovimento'].getRawValue() === 'ENTRATA') {
      if (typePayment == 'ASSEGNO') {
        this.form.controls['banca'].enable()
        this.form.controls['numeroAssegno'].enable()
      } else {
        this.form.controls['banca'].disable()
        this.form.controls['numeroAssegno'].disable()
      }
    }
  }

  departmentChanged(department: string) {
    if (!this.isNewMovement) {
      this.departmentUpdating = department

      this.confirmChangeDepartment()

      return
    }

    this.typePayments = [
      {key: 'CONTANTI', value: 'Contanti'},
      {key: 'ASSEGNO', value: 'Assegno'},
      {key: 'POS', value: 'POS'},
      {key: 'BONIFICO', value: 'Bonifico'},
    ]

    if (department === 'OFFICINA') {
      this.typePayments.push({key: 'CONTO_MEKKANO', value: 'Conto Mekkano'})
    }

    this.department = department

    this.refreshForm()

    this.form.controls['reparto'].setValue(department)
  }

  typeMovementChanged(typeMovement: string) {

    if (this.form.controls['reparto'].value === 'BACKOFFICE') {
      this.typeMovementSelected = typeMovement;

      this.updateFieldsTypeMovement(this.form.controls['reparto'].value, typeMovement);

      this.form.controls['tipoMovimento'].setValue(typeMovement)
    }

  }

  public confirmChangeDepartment(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(changeDepartment => {
      const oldDepartment = this.department
      const newDepartmentSelected = this.departmentUpdating

      if (!changeDepartment) {
        this.department = oldDepartment
      } else {
        this.department = newDepartmentSelected
      }

      this.refreshForm()

      this.form.controls['reparto'].setValue(this.department)
    });
  }

  onSubmit() {
    let cashMovement = <CashMovementsModel>this.form.getRawValue()

    this.cashMovementsService.saveCashMovements(cashMovement).subscribe({
      next: (response) => {
        let cashMovementId = response.id

        this.toastr.success('Movimento di cassa salvato correttamente!');

        switch (this.actionAfterSave) {
          case "saveNew":
            this.actionAfterSave = 'save'
            window.location.reload();

            break;
          case "saveDuplicate":
            this.actionAfterSave = 'save'
            this.form.controls['importo'].setValue('')
            this.form.get('dataMovimento').value.subtract(1, 'd')

            break;
          case "save":
            this.actionAfterSave = 'save'
            this.router.navigate([`cash-management/cash-movements/details/${cashMovementId}`])
            break;
        }
      },
      error: (error) => {
        LoggerService.error('saveCashMovements failed!');
        LoggerService.error(error);

        this.manageErrorMessages(error)

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected loadDataMovement() {
    this.cashMovementsService.getMovementsById(this.movementId).subscribe({
      next: (cashMovement) => {
        this.data = <CashMovementsModel>cashMovement
        this.department = this.data.reparto
        this.primaryButton = `Salva movimento`
        this.typeMovementSelected = this.data.tipoMovimento

        this.refreshForm()
        this.updateFieldsTypeMovement(this.department, this.data.tipoMovimento);
        this.typePaymentChange(this.data.tipoPagamento)
      },
      error: (error) => {
        LoggerService.error('getMovementById failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  protected secondButtonAction() {

    this.actionAfterSave = "saveNew"
    this.onSubmitForm()
  }

  protected thirdButtonAction() {
    this.actionAfterSave = "saveDuplicate"
    this.onSubmitForm()
  }

  protected setupMeta() {
    this.meta = CashMovementsForm
      .setClassToCall('NewCashMovementsFormComponent')
      .set('departments', this.departments)
      .set('movementTypes', this.movementTypes)
      .set('isNewMovement', this.isNewMovement)
      .set('department', this.department)
      .set('typePayments', this.typePayments)
      .set('isAccountingUser', this.isAccountingUser)
      .set('isAdminUser', this.isAdminUser)
      .set('banksAccount', this.banks)
      .set('typeMovementSelected', this.typeMovementSelected)
      .getMeta()
  }

  protected manageErrorMessages(error: HttpErrorResponse) {
    const errorKey = error.error.key ?? ''

    switch (errorKey) {
      case "CASH_FLOW_LOCKED":
        this.toastr.error(`Non è possibile salvare questo movimento in quanto è stato fatto un fondo cassa in data seguente.`);
        break;
      default:
        this.toastr.error(`Errore durante il salvataggio del movimento.`);
        break;
    }
  }

  private updateFieldsTypeMovement(department: string, typeMovement: string) {
    switch (typeMovement) {
      case 'VERSAMENTO':
        this.typePayments = [
          {key: 'CONTANTI', value: 'Contanti'},
          {key: 'ASSEGNO', value: 'Assegno'},
        ]

        this.refreshForm()

        this.form.controls['bancaVersamento'].enable()
        this.form.controls['descrizioneMovimento'].enable()

        break;
      case 'FONDO_CASSA':
        this.typePayments = [
          {key: 'CONTANTI', value: 'Contanti'},
          {key: 'ASSEGNO', value: 'Assegno'},
        ]
        this.refreshForm()

        break;
      case 'USCITA':
        this.typePayments = [
          {key: 'CONTANTI', value: 'Contanti'},
        ]

        this.refreshForm()

        this.form.controls['descrizioneMovimento'].enable()
        break;
      case 'ENTRATA':
        this.typePayments = [
          {key: 'CONTANTI', value: 'Contanti'},
          {key: 'ASSEGNO', value: 'Assegno'},
          {key: 'POS', value: 'POS'},
          {key: 'BONIFICO', value: 'Bonifico'},
        ]
        if (department === 'OFFICINA') {
          this.typePayments.push({key: 'CONTO_MEKKANO', value: 'Conto Mekkano'})
        }
        break;
      default:
        this.typePayments = [
          {key: 'CONTANTI', value: 'Contanti'},
          {key: 'ASSEGNO', value: 'Assegno'},
          {key: 'POS', value: 'POS'},
          {key: 'BONIFICO', value: 'Bonifico'},
        ]

        this.refreshForm()

        break;
    }
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di cambiare reparto?'
    dialogRef.componentInstance.content = "Attenzione una volta cambiato il reparto, verranno aggiornati anche i campi sottostanti."
    dialogRef.componentInstance.firstCTA = "Si modifica"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }

}
