import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {BrandGoalsSettings} from "@/table-settings/practices/brand-goals-settings";
import {BrandGoalsService} from "@services/data-source-tables/brand-goals.service";

@Component({
  selector: 'app-brand-goals',
  templateUrl: './brand-goals.component.html',
  styleUrls: ['./brand-goals.component.scss']
})
export class BrandGoalsComponent {

  source: LocalDataSource;
  settings = BrandGoalsSettings.gridSettings();

  constructor(private brandGoalsService: BrandGoalsService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadBrandGoals();
    });

    Registry.getInstance().set('BrandGoalsComponent', this)

    this.loadBrandGoals();
  }

  loadBrandGoals() {
    this.brandGoalsService.getAll().subscribe((brandGoals) => {
      this.source.load(brandGoals);
    });
    this.brandGoalsService.getAllBrands().subscribe(brands => {
      const options = [];
      for (const brand of brands) {
        options.push({
          value: brand.id,
          title: brand.codiceMarca + ' - ' + brand.descrizioneMarca
        });
      }

      this.settings.columns.marca.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
  }
}
