import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {SubAgentFormSetting} from "@/form-settings/sub-agent-form-setting";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {SubAgent} from "@/models/sub-agent";
import {SubagentAgencyService} from "@services/data-source-tables/subagent-agency.service";
import {OptionSelect} from "@/models/option-select.model";

@Component({
  selector: 'app-sub-agent-form',
  templateUrl: '../../../../components/dynamic-form/dynamic-form.component.html',
})
export class SubAgentFormComponent extends DynamicFormComponent implements OnInit {
  private users: OptionSelect[];
  private referenti: OptionSelect[];

  constructor(protected router: Router, protected userService: UserService, protected subAgencyService: SubagentAgencyService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    Registry.getInstance().set('SubAgentFormComponent', this)
  }

  ngOnInit(): void {
    this.subAgencyService.getResponsibleSubagentUsers().subscribe(users => {
      this.users = users.map(user => ({
        key: user,
        value: user.username
      }))
      this.setupMeta()
    })

    this.subAgencyService.getReferentsUsers().subscribe(users => {
      this.referenti = users.map(user => ({
        key: user,
        value: user.username
      }))
      this.setupMeta()
    })
  }

  onSubmit() {
    let subagent = <SubAgent>this.form.getRawValue()
    subagent.enabled = subagent.enabled ?? false

    this.subAgencyService.saveSubAgent(subagent).subscribe({
      next: () => {
        this.toastr.success('Subagente salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('createUser failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della subagente.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = SubAgentFormSetting
      .set('users', this.users)
      .set('referenti', this.referenti)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogSubAgentComponentModify').close()
    } else {
      Registry.getInstance().get('DialogSubAgentComponentNew').close()
    }

    Registry.getInstance().get('SubagentAgencyComponent').loadSubagentAgency()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogSubAgentComponentModify').close()
    } else {
      Registry.getInstance().get('DialogSubAgentComponentNew').close()
    }
  }
}
