import {Component, Input, OnChanges} from '@angular/core';
import {PracticeModel} from "@/models/practice.model";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {SellerPracticeInvoiceDataSource} from "@components/new-table/data-sources/seller-practice-invoice.dataSource";
import {BuyerPracticeInvoiceDataSource} from "@components/new-table/data-sources/buyer-practice-invoice.dataSource";
import {BuyerPracticeInvoiceSettingsTable} from "@/table-settings/practices/buyer-practice-invoice-settings-table";
import {SellerPracticeInvoiceSettingsTable} from "@/table-settings/practices/seller-practice-invoice-settings-table";
import {Subject} from "rxjs";
import {
  QuadratureAccountingPracticesSettingsTable
} from "@/table-settings/practices/quadrature-accounting-practices-settings-table";
import {PracticeCashMovementsSettingsTable} from "@/table-settings/practices/practice-cash-movements-settings-table";
import {PracticeCashMovementsDataSource} from "@components/new-table/data-sources/practice-cash-movements.dataSource";

@Component({
  selector: 'app-accounting-tab-component',
  templateUrl: './accounting-tab-component.component.html',
  styleUrls: ['./accounting-tab-component.component.scss']
})
export class AccountingTabComponentComponent implements OnChanges {
  @Input() formData!: PracticeModel;
  panelOpenState: boolean;
  displayedColumnsSellerInvoice: TableColumnsSetting<any>;
  dataSourceSellerInvoice = null;
  dataSourceBuyerInvoice = null;
  displayedColumnsBuyerInvoice: TableColumnsSetting<any>;
  dataSourcePracticeQuadrature = null;
  displayedColumnsPracticeQuadrature: TableColumnsSetting<any>;
  dataSourcePracticeCashMovements = null;
  displayedColumnsCashMovements: TableColumnsSetting<any>;
  reloadTrigger$ = new Subject<any>();

  constructor() {
    this.displayedColumnsBuyerInvoice = BuyerPracticeInvoiceSettingsTable.getColumnsSetting();
    this.displayedColumnsSellerInvoice = SellerPracticeInvoiceSettingsTable.getColumnsSetting();
    this.displayedColumnsPracticeQuadrature = QuadratureAccountingPracticesSettingsTable.getColumnsSetting();
    this.displayedColumnsCashMovements = PracticeCashMovementsSettingsTable.getColumnsSetting();
  }

  public ngOnChanges() {
    if (Object.values(this.formData).length !== 0) {
      this.dataSourceSellerInvoice = new SellerPracticeInvoiceDataSource();
      this.dataSourceSellerInvoice.content = this.formData.lineaFatturaVenditaList;
      this.dataSourceSellerInvoice.totalElements = 1;

      this.dataSourceBuyerInvoice = new BuyerPracticeInvoiceDataSource();
      this.dataSourceBuyerInvoice.content = this.formData.lineaFatturaAcquistoList;
      this.dataSourceBuyerInvoice.totalElements = 1;

      this.dataSourcePracticeQuadrature = new BuyerPracticeInvoiceDataSource();
      this.dataSourcePracticeQuadrature.content = this.formData.quadraturaContabilitaPraticaList;
      this.dataSourcePracticeQuadrature.totalElements = 1;

      this.dataSourcePracticeCashMovements = new PracticeCashMovementsDataSource();
      this.dataSourcePracticeCashMovements.content = this.formData.movimentiPraticaList;
      this.dataSourcePracticeCashMovements.totalElements = 1;
    }
  }
}
