<input
    type="checkbox"
    [id]="ID"
    [ngClass]="{
        'form-check-input': type === 'default',
        'custom-control-input': type === 'custom'
    }"
    [checked]="value"
    (change)="onValueChange($event)"
/>
<label
    [for]="ID"
    [ngClass]="{
        'form-check-label': type === 'default',
        'custom-control-label': type === 'custom'
    }"
>
    <ng-content></ng-content>
</label>
