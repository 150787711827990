import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class PracticeCashMovementsSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "id",
        text: "Id"
      },
      {
        name: "reparto",
        text: "Reparto"
      },
      {
        name: 'dataMovimento',
        type: "date",
        text: 'Data movimento'
      },
      {
        name: "descrizioneMovimento",
        text: "Descrizione"
      },
      {
        name: "importo",
        text: "Importo",
        type: "currency"
      },
      {
        name: "tipoPagamento",
        text: "Tipo pagamento"
      },
      {
        name: "note",
        text: "Note"
      },
      {
        name: "creator",
        text: "Creato da"
      }
    ];
  }
}
