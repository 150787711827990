import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";
import {PaymentTypeService} from "@services/data-source-tables/payment-type.service";
import {DialogPaymentTypeComponent} from "@pages/tables/payment-type/dialog-payment-type/dialog-payment-type.component";

@Component({
  selector: 'app-button-detail-payment-type',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailPaymentTypeComponent extends ButtonComponent {
  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private paymentTypeModel: PaymentTypeModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, protected paymentTypeService: PaymentTypeService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Tipo pagamento'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.paymentTypeModel = <PaymentTypeModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogPaymentTypeComponent, {
      data: this.paymentTypeModel,
    });

    Registry.getInstance().set('DialogPaymentTypeComponent', this.dialogOpened)
  }

}
