import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {InsuranceServiceTypeFormSetting} from "@/form-settings/insurance-service-type-form-setting";
import {InsuranceServicesTypeService} from "@services/data-source-tables/insurance-services-type.service";
import {InsuranceServiceTypeModel} from "@/models/tables/insurance-service-type.model";

@Component({
  selector: 'app-insurance-service-type-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class InsuranceServiceTypeFormComponent extends DynamicFormComponent {
  constructor(protected router: Router, protected userService: UserService, protected insuranceServicesTypeService: InsuranceServicesTypeService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let sellerModel = <InsuranceServiceTypeModel>this.form.getRawValue()

    this.insuranceServicesTypeService.saveInsuranceServiceType(sellerModel).subscribe({
      next: () => {
        this.toastr.success('Servizio assicurativo salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveInsuranceServiceType failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Servizio assicurativo.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = InsuranceServiceTypeFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogInsuranceServiceTypeComponentModify').close()
    } else {
      Registry.getInstance().get('DialogInsuranceServiceTypeComponentNew').close()
    }

    Registry.getInstance().get('InsuranceServiceTypeComponent').loadInsuranceServiceTypes()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogInsuranceServiceTypeComponentModify').close()
    } else {
      Registry.getInstance().get('DialogInsuranceServiceTypeComponentNew').close()
    }
  }

}
