<div class="container-box">
  <app-table-header (btnUpdateClick)="reloadTrigger$.next()" tableTitle="Quadrature Cassa">
  </app-table-header>
  <div class="table-container">
    <app-table-group-rows (buttonClick)="downloadExcelExport($event)" [columns]="columns"
                          [data]="data"
                          [icon]="faFileExcel"
                          [showButtonRow]="true"
                          [subColumns]="subColumns"></app-table-group-rows>
  </div>
</div>
