import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {BigBuyersService} from "@services/data-source-tables/big-buyers.service";
import {BigBuyersSettings} from "@/table-settings/tables/big-buyers-settings";

@Component({
  selector: 'app-big-buyers',
  templateUrl: './big-buyers.component.html',
  styleUrls: ['./big-buyers.component.scss']
})
export class BigBuyersComponent {

  source: LocalDataSource;
  settings = BigBuyersSettings.gridSettings();

  constructor(private bigBuyersService: BigBuyersService) {
    this.source = new LocalDataSource([]);
    this.bigBuyersService.getAllCustomerTypes().subscribe(customerTypes => {
      const options = [];
      for (const customerType of customerTypes) {
        options.push({
          value: customerType,
          title: customerType
        });
      }

      this.settings.columns.tipoCliente.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
    this.bigBuyersService.getAllBrands().subscribe(brands => {
      const options = [];
      for (const brand of brands) {
        options.push({
          value: brand,
          title: brand
        });
      }

      this.settings.columns.marca.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    Registry.getInstance().set('BigBuyersComponent', this)

    this.loadBigBuyers();
  }

  loadBigBuyers() {
    this.bigBuyersService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
