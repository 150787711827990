import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {RentalPracticeModel} from "@/models/rental-practice.model";

@Component({
  selector: 'app-dialog-rentable-practice-new',
  templateUrl: './dialog-rentable-practice-new.component.html',
})
export class DialogRentablePracticeNewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RentalPracticeModel) {
  }
}
