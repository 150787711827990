<div class="table-container">
  <table class="table">
    <thead>
    <tr>
      <th></th> <!-- Column for the icon open sub group -->
      <th *ngIf="showButtonRow"></th> <!-- Column for the icon download excel-->
      <th *ngFor="let column of columns">{{ column.header }}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of data">
      <!-- Main row -->
      <tr [class.expanded]="row === expandedRow">
        <td (click)="onRowClick(row)" [class.row-selected]="row === expandedRow" class="icon-cell cursor-pointer">
          <i *ngIf="expandedRow === row && row.movimenti.length !== 0" class="icon-minus">[-]</i>
          <i *ngIf="expandedRow !== row && row.movimenti.length !== 0" class="icon-plus">[+]</i>
        </td>
        <td (click)="buttonClickRow(row)" *ngIf="showButtonRow" [class.row-selected]="row === expandedRow"
            class="icon-cell">
          <span *ngIf="row.movimenti.length !== 0"
                class="bg-green-600 py-1 px-2 rounded-lg text-white cursor-pointer">
             <fa-icon [icon]="icon"></fa-icon>
          </span>
        </td>
        <td *ngFor="let column of columns" [class.row-selected]="row === expandedRow">{{ row[column.field] }}</td>
      </tr>

      <!-- Sub rows for the expanded main row -->
      <tr *ngIf="row === expandedRow && row.movimenti.length !== 0">
        <td [attr.colspan]="columns.length + 1"> <!-- Adjust colspan to include the icon column -->
          <table class="nested-table">
            <thead>
            <tr>
              <th *ngFor="let subColumn of subColumns">{{ subColumn.header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let subRow of row.movimenti">
              <td *ngFor="let subColumn of subColumns">{{ subRow[subColumn.field] }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
