import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {SellersService} from "@services/data-source-tables/sellers.service";
import {SellerSettings} from "@/table-settings/tables/seller-settings";

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent {

  source: LocalDataSource;
  settings = SellerSettings.gridSettings();

  constructor(private sellersService: SellersService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadSellers();
    });

    Registry.getInstance().set('SellerComponent', this)

    this.sellersService.getSubagentAgencies().subscribe(subagentAgencies => {
      const options = [];
      for (const subagentAgency of subagentAgencies) {
        options.push({
          value: subagentAgency.id,
          title: subagentAgency.descrizione
        });
      }

      this.settings.columns.agenziaSubagente.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    this.sellersService.getAllBrands().subscribe(brands => {
      const options = [];
      for (const brand of brands) {
        options.push({
          value: brand,
          title: brand
        });
      }

      this.settings.columns.marca.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    this.sellersService.getAllUsers().subscribe(users => {
      this.settings.columns.users.filter.config.list = users.map(({username}) => ({
        value: username,
        title: username
      }))
      this.settings = Object.assign({}, this.settings);
    });

    this.sellersService.getSellerTypes().subscribe(sellerTypes => {
      const options = [];
      for (const sellerType of sellerTypes) {
        options.push({
          value: sellerType,
          title: sellerType
        });
      }

      this.settings.columns.tipoVenditore.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });

    this.loadSellers();
  }

  loadSellers() {
    this.sellersService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }


}
