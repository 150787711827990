import {OptionSelect} from "@/models/option-select.model";
import {BankPaymentModel} from "@/models/cash-movements.model";

export class MetaBackOffice {

  public static getMeta(
    classToCall: string,
    isDetail: boolean,
    department: string,
    departments: OptionSelect[] = [],
    movementTypes: OptionSelect[] = [],
    isAccountingUser: boolean = false,
    isNewMovement: boolean = false,
    isAdminUser: boolean = false,
    typePayments: OptionSelect[] = [],
    banksAccount: BankPaymentModel[] = [],
    typeMovementSelected: string = ''
  ) {
    return [
      {
        "rows": [
          {
            "classToUse": classToCall,
            "key": "reparto",
            "controlType": "dropdown",
            "label": "Reparto",
            "order": 1,
            "default": department,
            "options": departments,
            "class": "lg:w-4/12",
            "customAction": "departmentChanged",
            "required": true,
            "disabled": !isAdminUser && !isAccountingUser && !isNewMovement
          },
          {
            "classToUse": classToCall,
            "key": "tipoMovimento",
            "controlType": "dropdown",
            "label": "Tipo Movimento",
            "order": 2,
            "default": "ENTRATA",
            "class": "lg:w-4/12",
            "required": true,
            "customAction": "typeMovementChanged",
            "options": movementTypes
          },
          {
            "classToUse": classToCall,
            "key": "dataMovimento",
            "controlType": "date",
            "default": new Date(),
            "label": "Data",
            "maxDate": !isAdminUser && !isAccountingUser,
            "order": 1,
            "class": "lg:w-4/12",
            "required": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": classToCall,
            "key": "creator",
            "controlType": isDetail ? "textinput" : "hidden",
            "label": "Utente",
            "order": 1,
            "class": "lg:w-4/12",
            "disabled": true
          },
          {
            "classToUse": classToCall,
            "key": "id",
            "controlType": isDetail ? "textinput" : "hidden",
            "label": "Id",
            "order": 1,
            "class": "lg:w-8/12",
            "disabled": true
          },
        ],
        "order": 1
      },
      {
        "rows": this.getFieldsTypePaymentEntry(typeMovementSelected, classToCall, typePayments),
        "order": 1
      },
      {
        "rows": this.getFieldsTypePaymentPayment(typeMovementSelected, classToCall, banksAccount, typePayments),
        "order": 1
      },
      {
        "rows": this.getFieldsTypePaymentOutput(typeMovementSelected, classToCall, typePayments),
        "order": 1
      },
      {
        "rows": this.getFieldsTypePaymentCashFund(typeMovementSelected, classToCall, typePayments),
        "order": 1
      },
    ]
  }

  private static getFieldsTypePaymentOutput(typeMovementSelected: string, classToCall: string, typePayments: OptionSelect[]) {
    return typeMovementSelected == 'USCITA' ? [
      {
        "classToUse": classToCall,
        "key": "descrizioneMovimento",
        "controlType": "textinput",
        "label": "Descrizione",
        "order": 1,
        "class": "w-full",
        "required": true,
      },
      {
        "classToUse": classToCall,
        "key": "importo",
        "controlType": "number",
        "label": "Importo",
        "order": 1,
        "class": "lg:w-4/12",
        "required": true,
        "disabled": false,
      },
      {
        "classToUse": classToCall,
        "key": "tipoPagamento",
        "controlType": "dropdown",
        "label": "Tipo pagamento",
        "order": 2,
        "customAction": "typePaymentChange",
        "required": true,
        "class": "lg:w-4/12",
        "options": typePayments,
        "disabled": false,
      },
    ] : [];
  }

  private static getFieldsTypePaymentPayment(typeMovementSelected: string, classToCall: string, banksAccount: BankPaymentModel[], typePayments: OptionSelect[]) {
    return typeMovementSelected == 'VERSAMENTO' ? [
      {
        "customAction": "",
        "required": false,
        "classToUse": classToCall,
        "key": "bancaVersamento",
        "controlType": "autocomplete-bank-payment",
        "label": "Banca Versamento",
        "order": 1,
        "class": "lg:w-4/12",
        "disabled": false,
        "options": banksAccount
      },
      {
        "classToUse": classToCall,
        "key": "importo",
        "controlType": "number",
        "label": "Importo",
        "order": 1,
        "class": "lg:w-4/12",
        "required": true,
        "disabled": false,
      },
      {
        "classToUse": classToCall,
        "key": "tipoPagamento",
        "controlType": "dropdown",
        "label": "Tipo pagamento",
        "order": 2,
        "customAction": "typePaymentChange",
        "required": true,
        "class": "lg:w-4/12",
        "options": typePayments,
        "disabled": false,
      },
      {
        "classToUse": classToCall,
        "key": "descrizioneMovimento",
        "controlType": "textinput",
        "label": "Descrizione",
        "order": 1,
        "class": "w-full",
        "required": true,
      },
    ] : [];
  }

  private static getFieldsTypePaymentEntry(typeMovementSelected: string, classToCall: string, typePayments: OptionSelect[]) {
    return typeMovementSelected == 'ENTRATA' ?
      [
        {
          "classToUse": classToCall,
          "key": "importo",
          "controlType": "number",
          "label": "Importo",
          "order": 1,
          "class": "lg:w-4/12",
          "required": true,
          "disabled": false,
        },
        {
          "classToUse": classToCall,
          "key": "tipoPagamento",
          "controlType": "dropdown",
          "label": "Tipo pagamento",
          "order": 2,
          "customAction": "typePaymentChange",
          "required": true,
          "class": "lg:w-4/12",
          "options": [
            {key: 'CONTANTI', value: 'Contanti'},
            {key: 'ASSEGNO', value: 'Assegno'},
            {key: 'POS', value: 'POS'},
            {key: 'BONIFICO', value: 'Bonifico'},
          ],
          "disabled": false,
        },
        {
          "classToUse": classToCall,
          "key": "abbuono",
          "controlType": "number",
          "label": "Abbuono",
          "order": 2,
          "class": "w-4/12",
        },
        {
          "classToUse": classToCall,
          "key": "numeroAssegno",
          "controlType": "textinput",
          "label": "Numero assegno",
          "order": 1,
          "class": "lg:w-4/12",
          "disabled": true,
        },
        {
          "classToUse": classToCall,
          "key": "banca",
          "controlType": "textinput",
          "label": "Banca",
          "order": 1,
          "class": "lg:w-4/12",
          "disabled": true,
        },
        {
          "classToUse": classToCall,
          "key": "numeroContrattoFinanziamento",
          "controlType": "textinput",
          "label": "Numero Contratto Finanziamento",
          "order": 1,
          "class": "lg:w-4/12",
        },
        {
          "classToUse": classToCall,
          "key": "codice",
          "controlType": "textinput",
          "label": "Codice Cliente",
          "order": 1,
          "class": "lg:w-4/12",
          "required": true,
        },
        {
          "classToUse": classToCall,
          "key": "descrizioneMovimento",
          "controlType": "textinput",
          "label": "Nome Cognome/Ragione Sociale",
          "order": 1,
          "class": "lg:w-4/12",
          "required": true,
        },
        {
          "classToUse": classToCall,
          "key": "numeroPratica",
          "controlType": "textinput",
          "label": "Numero Pratica",
          "order": 1,
          "class": "lg:w-4/12",
        },
        {
          "classToUse": classToCall,
          "key": "targa",
          "controlType": "textinput",
          "label": "Targa",
          "order": 1,
          "class": "lg:w-4/12",
        },
        {
          "classToUse": classToCall,
          "key": "telaio",
          "controlType": "textinput",
          "label": "Telaio",
          "order": 1,
          "class": "lg:w-4/12",
        },
        {
          "classToUse": classToCall,
          "key": "note",
          "controlType": "textarea",
          "label": "Note",
          "order": 2,
          "class": "w-full",
        },
      ] : [];
  }

  private static getFieldsTypePaymentCashFund(typeMovementSelected: string, classToCall: string, typePayments: OptionSelect[]) {
    return typeMovementSelected == 'FONDO_CASSA' ? [
      {
        "classToUse": classToCall,
        "key": "importo",
        "controlType": "number",
        "label": "Importo",
        "order": 1,
        "class": "lg:w-4/12",
        "required": true,
      },
      {
        "classToUse": classToCall,
        "key": "tipoPagamento",
        "controlType": "dropdown",
        "label": "Tipo pagamento",
        "order": 2,
        "customAction": "typePaymentChange",
        "required": true,
        "class": "lg:w-4/12",
        "options": typePayments,
      },
    ] : [];
  }
}
