import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faDownload} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-table-group-rows',
  templateUrl: './table-group-rows.component.html',
  styleUrls: ['./table-group-rows.component.scss']
})
export class TableGroupRowsComponent {
  @Input() columns: { field: string; header: string }[] = [];
  @Input() data: any[] = [];
  @Input() subColumns: { field: string; header: string }[] = [];
  @Output() buttonClick = new EventEmitter<unknown>();
  @Input() showButtonRow: boolean = false;
  @Input() icon: any = faDownload;

  expandedRow: any = null;

  onRowClick(row: any): void {
    // Toggle expansion for the clicked row
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  buttonClickRow(row: any) {
    this.buttonClick.emit(row);
  }
}
