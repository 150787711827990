import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {MenuBiConfigService} from "@services/data-source-tables/menu-bi-config.service";
import {MenuBiConfigSettings} from "@/table-settings/tables/menu-bi-config-settings";

@Component({
  selector: 'app-menu-bi-config',
  templateUrl: './menu-bi-config.component.html',
  styleUrls: ['./menu-bi-config.component.scss']
})
export class MenuBiConfigComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = MenuBiConfigSettings.gridSettings();

  constructor(private menuBiConfigService: MenuBiConfigService) {
    this.loadMenuBiConfig();

    Registry.getInstance().set('MenuBiConfigComponent', this)
  }

  loadMenuBiConfig() {
    this.menuBiConfigService.getAll().subscribe((menuBis) => {
      LoggerService.log(menuBis, 'menuBis')

      this.source.load(menuBis);
    });

    this.menuBiConfigService.getAreas().subscribe(areas => {
      const options = [];
      for (const area of areas) {
        options.push({
          value: area,
          title: area
        });
      }

      this.settings.columns.areaBi.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
  }
}
