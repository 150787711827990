import {Component, inject} from '@angular/core';
import {Subject} from "rxjs";
import {CashMovementsSettings} from "@/table-settings/cash-management/cash-movements-settings";
import {CashMovementsDataSource} from "@components/new-table/data-sources/cash-movements.dataSource";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {CashMovementsTableModel} from "@/models/tables/cash-movements-table.model";
import {CashMovementsService} from "@services/cash-movements.service";
import {Router} from "@angular/router";
import {CashMovementsModel} from "@/models/cash-movements.model";
import {UserService} from "@services/admin/user.service";
import {Role} from "@/models/role";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-cash-movements',
  templateUrl: './cash-movements.component.html',
  styleUrls: ['./cash-movements.component.scss']
})
export class CashMovementsComponent {

  displayedColumns = CashMovementsSettings.getColumnsSetting(this.cashMovementsService)
  dataSource = new CashMovementsDataSource(this.cashMovementsService);
  reloadTrigger$ = new Subject<any>();
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  hideButtonDelete: boolean = false;

  constructor(
    private cashMovementsService: CashMovementsService,
    private router: Router,
    private userService: UserService
    ) {
    const rolesUser = this.userService.getUserRoles();
    this.hideButtonDelete = !(rolesUser.includes(Role.Admin) || rolesUser.includes(Role.Accounting));
  }

  btnDeleteFunction({id: cashMovementId}: CashMovementsTableModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deletePractice => {
      if (deletePractice) {
        this.cashMovementsService.deleteCashMovements(cashMovementId).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Movimento di cassa cancellato correttamente!');
          },
          error: (error) => {
            LoggerService.error('deleteCashMovements failed!')
            LoggerService.error(error);

            this.manageErrorMessages(error)
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  protected manageErrorMessages(error: HttpErrorResponse) {
    const errorKey = error.error.key ?? ''

    switch (errorKey) {
      case "CASH_FLOW_LOCKED":
        this.toastr.error(`Non è possibile cancellare questo movimento in quanto è stato fatto un fondo cassa in data seguente.`);
        break;
      default:
        this.toastr.error(`Errore durante la cancellazione del movimento di cassa.`);
        break;
    }
  }

  btnDetailsClickFunction({ id }: CashMovementsModel) {
    this.router.navigate([`cash-management/cash-movements/details/${id}`])
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo Movimento di cassa?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata il Movimento di cassa non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
