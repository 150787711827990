<div class="container-box mb-4 w-full">
  <h2 class="text-dark text-lg mx-1 my-2" mat-dialog-title>
    <fa-icon [icon]="faFile"></fa-icon>
    Dettaglio Pratica Noleggio - Nr. Pratica {{ data.praticaId }}
  </h2>
  <table class="ml-5 mb-3 w-full">
    <tr>
      <td>Targa: {{ data.targa ?? '-' }}</td>
      <td>Marca: {{ data.marca ?? '-' }}</td>
      <td>Modello: {{ data.modello ?? '-' }}</td>
    </tr>
    <tr>
      <td>Categoria Veicolo: {{ data.categoriaVeicolo ?? '-' }}</td>
      <td>Stato Contratto: {{ data.statoContratto ?? '-' }}</td>
    </tr>
  </table>
</div>
<div class="container-box">
  <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
                    <span *ngIf="tab.faIcon">
                        <fa-icon [icon]="tab.faIcon"></fa-icon>
                    </span>
        {{ tab.title }}
      </ng-template>
      <div [ngSwitch]="tab.title" class="content">
        <div *ngSwitchCase="'Anagrafica noleggio'">
          <app-rent-practice-form
            [data]="data"
          ></app-rent-practice-form>
        </div>
        <div *ngSwitchCase="'Cliente noleggio'">
          DA DEFINIRE Cliente noleggio
        </div>
        <div *ngSwitchCase="'Movimenti noleggio'">
          DA DEFINIRE movimenti noleggio
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
